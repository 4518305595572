// 선명도 -> ??%;
export const clarify = (color: string, clarity = 100) => {
  if (clarity < 0 || clarity > 100) throw new Error('Clarity percentage should be between 0 and 100.');

  const clarityAlpha = Math.round((clarity / 100) * 255)
    .toString(16)
    .padStart(2, '0');
  return color.slice(0, 7) + clarityAlpha;
};

// 선명도 -> 0%;
export const transparentize = (color: string) => {
  return clarify(color, 0);
};

export const isTransparent = (color: string) => {
  return color.slice(7, 9) === '00';
};

//색상 더 어둡게
export const darkenColor = (color: string, amount: number): string => {
  // '#' 제거 및 3자리 헥스 확장
  let hex = color.startsWith('#') ? color.slice(1) : color;
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map((ch) => ch + ch)
      .join('');
  }
  if (hex.length !== 6) return color;

  // 헥스 -> 각 채널(R, G, B) 파싱
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  // 검은색(#000000)은 (0,0,0)이므로,
  // 선형 보간으로 새로운 채널 값 계산: amount가 클수록 원본 색상에 곱해지는 비율이 줄어듭니다.
  const newR = Math.round(r * (1 - amount));
  const newG = Math.round(g * (1 - amount));
  const newB = Math.round(b * (1 - amount));

  // 각 채널을 16진수 문자열로 변환 후 합침
  const toHex = (c: number) => c.toString(16).padStart(2, '0');
  return `#${toHex(newR)}${toHex(newG)}${toHex(newB)}`;
};

// 색상 더 밝게
export const lightenColor = (color: string, amount: number): string => {
  // '#' 제거 및 3자리 헥스 확장
  let hex = color.startsWith('#') ? color.slice(1) : color;
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map((ch) => ch + ch)
      .join('');
  }
  if (hex.length !== 6) return color;

  // 헥스 -> 각 채널(R, G, B) 파싱
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  // 흰색은 (255, 255, 255) 이므로, 선형 보간으로 새로운 채널 값 계산
  const newR = Math.round(r + (255 - r) * amount);
  const newG = Math.round(g + (255 - g) * amount);
  const newB = Math.round(b + (255 - b) * amount);

  // 각 채널을 16진수 문자열로 변환 후 합침
  const toHex = (c: number) => c.toString(16).padStart(2, '0');
  return `#${toHex(newR)}${toHex(newG)}${toHex(newB)}`;
};
