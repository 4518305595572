import { useEffect, useMemo, useRef, useState } from 'react';
import useWindowResize from '../../hook/useWindowResize';
import { VIEW_MODE, ViewModeType } from '../../interface/common/ViewMode';
import {
  EDITOR_HEADER_HEIGHT,
  EDITOR_MOBILE_PREVIEW_NOTICE_HEIGHT,
  PREVIEW_HEADER_HEIGHT,
} from './viewHeader.constants';

interface IProps {
  viewMode?: ViewModeType;
  isEdit?: boolean;
  isPreview?: boolean;
  isLLMTemplatePreview: boolean;
  isMobileDrawerOpen?: boolean;
}

const useViewHeaderAnimation = ({
  viewMode = VIEW_MODE.PC,
  isEdit,
  isPreview,
  isLLMTemplatePreview,
  isMobileDrawerOpen,
}: IProps) => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const viewHeaderRef = useRef<HTMLDivElement>(null);
  const { width: windowWidth, scrollBarWidth } = useWindowResize({ debounceMs: 50 });

  const width = useMemo(() => {
    const containerRef = viewHeaderRef.current?.parentElement;
    return containerRef?.offsetWidth ? `${containerRef?.offsetWidth}px` : `calc(${windowWidth - scrollBarWidth}px)`;
  }, [viewMode, viewHeaderRef.current, windowWidth, isMobileDrawerOpen]);

  const top = useMemo(() => {
    if (viewMode === VIEW_MODE.PC) return 0;
    if (isLLMTemplatePreview) return 0;
    if (isEdit) return EDITOR_HEADER_HEIGHT + EDITOR_MOBILE_PREVIEW_NOTICE_HEIGHT;
    if (isPreview) return PREVIEW_HEADER_HEIGHT;
    return 0;
  }, [viewMode, isEdit, isPreview]);

  useEffect(() => {
    const handleScroll = () => {
      if (isMobileDrawerOpen) return setVisible(true);
      const currentScrollPos = window.scrollY;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [viewMode, prevScrollPos, isMobileDrawerOpen]);

  return { top, width, viewHeaderRef, visible };
};

export default useViewHeaderAnimation;
