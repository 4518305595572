import { getDesignMultiLanguageDefaultV1, getDesignV2 } from '@api/design';
import { IGetDesignMultiLanguageDefaultV1Rs } from '@interface/design/IGetDesignMultiLanguageDefaultV1';
import ISimpleListRsFindPageUrlRs from '@interface/design/ISimpleListRsFindPageUrlRs';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Language } from 'ui/constants/language';

export const QueryKey = {
  all: ['template'] as const,
  get: {
    all: () => [...QueryKey.all, 'get'],
    design: (languageType?: Language) => [...QueryKey.get.all(), 'design', languageType],
    multiLanguage: {
      all: () => [...QueryKey.get.all(), 'multi-language'],
      default: () => [...QueryKey.get.multiLanguage.all(), 'default'],
    },
  },
};

export const useQueryGetDesign = ({
  publicKey,
  languageType,
}: {
  publicKey?: string;
  languageType?: Language;
}): UseQueryResult<ISimpleListRsFindPageUrlRs, AxiosError> => {
  return useQuery({
    queryKey: QueryKey.get.design(languageType),
    queryFn: () => getDesignV2({ languageType }),
    enabled: !!publicKey,
    staleTime: Infinity,
    retry: 0,
  });
};

export const useQueryGetDesignMultiLanguageDefault = (): UseQueryResult<
  IGetDesignMultiLanguageDefaultV1Rs,
  AxiosError
> => {
  return useQuery({
    queryKey: QueryKey.get.multiLanguage.default(),
    queryFn: () => getDesignMultiLanguageDefaultV1(),
    staleTime: Infinity,
    retry: 0,
  });
};
