import { FC, ReactElement, ReactNode } from 'react';
import classnames from 'classnames/bind';
import style from './ModalFooter.module.scss';
import Button from '../../common/button/Button';
import { VIEW_MODE, ViewModeType } from '../../interface/common/ViewMode';
import Paragraph from '../../common/paragraph/Paragraph';

const cx = classnames.bind(style);

interface IProps {
  quitTitle?: ReactNode;
  quit?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  quitDisabled?: boolean;

  bottomLeftCustomSlot?: ReactElement;

  cancelTitle?: string;
  close?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  cancelDisabled?: boolean;

  btnTitle?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;

  viewMode?: ViewModeType;
}

const ModalFooter: FC<IProps> = ({
  quitTitle,
  quit,
  quitDisabled = false,

  bottomLeftCustomSlot,

  cancelTitle,
  close,
  cancelDisabled = false,

  btnTitle,
  onClick,
  disabled = false,

  viewMode,
}) => {
  switch (viewMode) {
    case VIEW_MODE.MOBILE:
      return (
        <div className={cx('mobile-container')}>
          {quit && (
            <button className={cx('mobile-btn')} onClick={quit} disabled={quitDisabled}>
              <Paragraph variant={'B3'} bold>
                {quitTitle ? quitTitle : '나가기'}
              </Paragraph>
            </button>
          )}
          {close && (
            <button className={cx('mobile-btn')} onClick={close} disabled={cancelDisabled}>
              <Paragraph variant={'B3'} bold>
                {cancelTitle ? cancelTitle : '취소'}
              </Paragraph>
            </button>
          )}
          <button className={cx('mobile-btn', 'submit')} onClick={onClick} disabled={disabled}>
            <Paragraph variant={'B3'} bold>
              {btnTitle ? btnTitle : '저장'}
            </Paragraph>
          </button>
        </div>
      );
    default:
      return (
        <div className={cx('container')}>
          <div className={cx('left-buttons')}>
            {quit && (
              <Button className={cx('btn', 'quit')} variant={'text'} size={'sm'} onClick={quit} disabled={quitDisabled}>
                {quitTitle ? quitTitle : '나가기'}
              </Button>
            )}
            {bottomLeftCustomSlot}
          </div>
          <div className={cx('right-buttons')}>
            {close && (
              <Button
                className={cx('btn', 'cancel')}
                variant={'text'}
                size={'sm'}
                onClick={close}
                disabled={cancelDisabled}
              >
                {cancelTitle ? cancelTitle : '취소'}
              </Button>
            )}
            {onClick && (
              <Button className={cx('btn', 'submit')} size={'sm'} onClick={onClick} disabled={disabled}>
                {btnTitle ? btnTitle : '저장'}
              </Button>
            )}
          </div>
        </div>
      );
  }
};

export default ModalFooter;
