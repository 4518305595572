import { CompanyQueryKey } from '@queries/company';
import { useQuery } from '@tanstack/react-query';
import { SOLUTION } from 'ui/constants/solution/solution.constant';
import { IPrivacyPolicy, IPrivacyPolicyItem } from 'ui/interface/footer/IFooterLink';
import { SOLUTION_TYPE } from 'ui/util/solution';
import { axios } from '../RequestClient';

interface IGetCompanyPrivacyPolicyListV1 {
  privacyPolicyList: IPrivacyPolicy[];
}

const getCompanyPrivacyPolicyListV1 = async (): Promise<IGetCompanyPrivacyPolicyListV1> => {
  const { data } = await axios.get('/company/v1/privacy-policy');
  return data;
};
const getCompanyPrivacyPolicyV1 = async (policySn: string): Promise<IPrivacyPolicyItem> => {
  const { data } = await axios.get('/company/v1/privacy-policy' + `/${policySn}`);
  return data;
};

export const useQueryGetCompanyPrivacyPolicyList = () => {
  return useQuery({
    queryKey: CompanyQueryKey.privacyPolicyList(),
    queryFn: getCompanyPrivacyPolicyListV1,
    enabled: SOLUTION_TYPE === SOLUTION.JOBFLEX,
    staleTime: Infinity,
  });
};

export const useQueryGetCompanyPrivacyPolicy = (policySn: string, endabled: boolean) => {
  return useQuery({
    queryKey: CompanyQueryKey.privacyPolicyItem(policySn),
    queryFn: () => getCompanyPrivacyPolicyV1(policySn),
    enabled: SOLUTION_TYPE === SOLUTION.JOBFLEX && endabled,
  });
};
