import React, { forwardRef } from 'react';
import classnames from 'classnames/bind';
import styles from './ViewFooter.module.scss';
import { ViewModeType } from '../../interface/common/ViewMode';
import IViewFooter from '../../interface/footer/IViewFooter';
import ViewFooterLink from './ViewFooterLink';
import ViewFooterText from './ViewFooterText';
import ViewFooterSNS from './ViewFooterSNS';
import { COLORS } from '../../constants/colors';
import { IPrivacyPolicy, IPrivacyPolicyItem } from '../../interface/footer/IFooterLink';
import { UseQueryResult } from '@tanstack/react-query';
import { HasOption } from '../../interface/common/hasOption';
const cx = classnames.bind(styles);

interface IViewFooterProps {
  viewMode?: ViewModeType;
  viewFooter?: IViewFooter;
  isPreview?: boolean;
  policyList?: IPrivacyPolicy[];
  querySelectedPolicy?: (policySn: string, endabled: boolean) => UseQueryResult<IPrivacyPolicyItem, unknown>;
}

const ViewFooter = forwardRef<HTMLDivElement, IViewFooterProps & HasOption>(function footer(
  { viewMode, viewFooter, isPreview = false, policyList, querySelectedPolicy, options },
  ref
) {
  if (!viewFooter) return null;

  const { colorType, footerLinkList, footerText, footerSNSList } = viewFooter!;

  return (
    <div className={cx('container', viewMode, { darkMode: colorType === 'BLACK' })} ref={ref}>
      <div className={cx('wrap')}>
        <div className={cx('info-wrap')}>
          <ViewFooterLink
            colorType={colorType}
            isPreview={isPreview}
            footerLinkList={footerLinkList}
            policyList={policyList}
            querySelectedPolicy={querySelectedPolicy}
            options={options}
          />
          <ViewFooterText viewMode={viewMode} footerText={footerText} colorType={colorType} />
        </div>
        <div className={cx('right-wrapper')}>
          <ViewFooterSNS
            viewMode={viewMode}
            colorType={colorType}
            footerSNSList={footerSNSList}
            isPreview={isPreview}
          />
        </div>
      </div>
    </div>
  );
});

export default ViewFooter;
