import classnames from 'classnames/bind';
import { ReadonlyURLSearchParams, usePathname, useSearchParams } from 'next/navigation';
import React, { FC, useEffect, useMemo, useRef } from 'react';
import Icon from '../../../common/icon/Icon';
import { COLORS } from '../../../constants/colors';
import useUrlRouter from '../../../hook/useUrlRouter';
import { AlignType } from '../../../interface/common/Align';
import { ViewModeType } from '../../../interface/common/ViewMode';
import {
  HIDE_TYPE,
  IFolderLNBType,
  ILNBType,
  ILNBTypes,
  IMyPageLNBType,
  ISingleLNBType,
  URL_TYPE,
} from '../../../interface/header/IMenu';
import styles from '../ViewHeader.module.scss';
import DrawerFolder from './DrawerFolder';
import { delay } from '../../../util/time';
import useWindowResize from '../../../hook/useWindowResize';
import {
  VIEW_HEADER_MOBILE_HEIGHT,
  EDITOR_MOBILE_PREVIEW_NOTICE_HEIGHT,
  EDITOR_HEADER_HEIGHT,
  PREVIEW_HEADER_HEIGHT,
} from '../viewHeader.constants';
import { SOLUTION_PATH_PREFIX, SOLUTION_TYPE } from '../../../util/solution';
import { HasOption } from '../../../interface/common/hasOption';
import { LANGUAGE } from '../../../constants/language';
import { useUrl } from '../../../hook/useUrl';
import { checkIsDarkMode } from '../../../util/editor/color.utils';
import IViewHeader from '../../../interface/header/IViewHeader';
import Link from 'next/link';
import { URL_PATH } from '../../../util/url.utils';

const cx = classnames.bind(styles);

interface IDrawerProps {
  viewHeader?: IViewHeader;
  viewMode?: ViewModeType;
  lnbList: ILNBType<ILNBTypes>[];
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isPreview?: boolean;
  isEdit?: boolean;
  style: {
    color: string;
    align: AlignType;
  };
}

const Drawer: FC<IDrawerProps & HasOption> = ({
  viewMode,
  lnbList,
  isPreview,
  isEdit,
  isOpen,
  setIsOpen,
  viewHeader,
  options,
  style,
}) => {
  const { handleUrlClick } = useUrlRouter();
  const searchParams = useSearchParams() as ReadonlyURLSearchParams;
  const pathname = usePathname();
  const { height: windowHeight } = useWindowResize();
  const { buildUrl, getSearchParam } = useUrl();
  const drawerRef = useRef<HTMLUListElement>(null);
  const iconName = isOpen ? 'close_line' : 'move_line';
  const languageType = options && options['languageType'];
  const hasLanguageChangeSwitch = options && options['hasLanguageChangeSwitch'];
  const handleLanguageSwitch = options && options['handleLanguageSwitch'];
  const { headerStyle } = viewHeader!;
  const { backgroundColor } = headerStyle;

  /** JOBFLEX Contents Mobile 대응이 안돼서 강제 조절 */
  useEffect(() => {
    const styleValid = (callback: () => void) => {
      if (!drawerRef?.current?.style) return;
      callback();
    };

    (async () => {
      if (!drawerRef?.current?.style) return;
      if (isOpen) {
        document.body.style.overflow = 'hidden';
        styleValid(() => (drawerRef.current!.style.display = 'block'));
        await delay(0);
        styleValid(() => (drawerRef.current!.style.transform = 'translate(0, 0)'));
      } else {
        styleValid(() => (drawerRef.current!.style.transform = 'translate(0, 0)'));
        await delay(300);
        styleValid(() => (drawerRef.current!.style.display = 'none'));
        document.body.style.overflow = 'auto';
      }
    })();
  }, [isOpen]);

  const height = useMemo(() => {
    const body = document.body;
    const html = document.documentElement;
    const maxViewHeight = Math.min(
      Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight),
      windowHeight
    );

    if (isEdit) {
      return maxViewHeight - VIEW_HEADER_MOBILE_HEIGHT - EDITOR_MOBILE_PREVIEW_NOTICE_HEIGHT - EDITOR_HEADER_HEIGHT;
    }
    if (isPreview) {
      return maxViewHeight - VIEW_HEADER_MOBILE_HEIGHT - PREVIEW_HEADER_HEIGHT;
    }
    return maxViewHeight - VIEW_HEADER_MOBILE_HEIGHT;
  }, [viewMode, isEdit, isPreview, windowHeight]);

  return (
    <>
      <ul
        className={cx('drawerWrap')}
        style={{
          height,
        }}
        ref={drawerRef}
      >
        {lnbList
          .filter(({ hideType }) => hideType === HIDE_TYPE.VISIBLE)
          .map((item, index) => {
            const menu = item as ILNBType<ISingleLNBType>;
            const subMenu = item as ILNBType<IFolderLNBType> & ILNBType<IMyPageLNBType>;
            const isExternal = menu.page?.urlType === URL_TYPE.EXTERNAL;

            const isCurrentPage = () => {
              const {
                page: { pageSn, url },
              } = menu;

              if (isEdit || isPreview) return Number(searchParams.get('pageSn')) === pageSn;
              return pathname && pathname === SOLUTION_PATH_PREFIX[`${SOLUTION_TYPE}`](url);
            };

            const handleClickMenu = async () => {
              const {
                page: { pageSn, url },
              } = menu;

              setIsOpen(false);
              if (isEdit) {
                return handleUrlClick({
                  url: isExternal
                    ? url
                    : buildUrl('/editor/setting')
                        .withSearchParam('designSn', getSearchParam('designSn'))
                        .withSearchParam('pageSn', pageSn)
                        .withSearchParam('languageType', languageType !== LANGUAGE.KOR ? languageType : null)
                        .getResult(),
                  isExternal,
                  isPreview,
                });
              }

              if (isPreview) {
                const pathname = window.location.pathname;
                if (pathname === '/editor/preview') {
                  return handleUrlClick({
                    url: isExternal
                      ? url
                      : buildUrl('/editor/preview')
                          .withSearchParam('designSn', getSearchParam('designSn'))
                          .withSearchParam('pageSn', pageSn)
                          .withSearchParam('languageType', languageType !== LANGUAGE.KOR ? languageType : null)
                          .getResult(),
                    isExternal,
                    isPreview,
                  });
                }

                if (pathname === '/template-preview') {
                  return handleUrlClick({
                    url: isExternal
                      ? url
                      : buildUrl('/template-preview')
                          .withSearchParam('pageSn', pageSn)
                          .withSearchParam('templateSn', getSearchParam('templateSn'))
                          .getResult(),
                    isExternal,
                    isPreview,
                  });
                }
              }

              handleUrlClick({
                url: isExternal
                  ? url
                  : buildUrl(url)
                      .withSearchParam('languageType', languageType !== LANGUAGE.KOR ? languageType : null)
                      .getResult(),
                isExternal,
                isPreview,
              });
            };

            switch (menu.formType) {
              case 'SINGLE':
                return (
                  <div key={index} className={cx('listItem', { active: isCurrentPage() })} onClick={handleClickMenu}>
                    <div className={cx('title-wrap')}>
                      <li className={cx('link')}>
                        <Link
                          href={`${URL_PATH[SOLUTION_TYPE](menu.page.url)}`}
                          onClick={(e) => e.preventDefault()}
                          prefetch={false}
                        >
                          {menu.title}
                        </Link>
                      </li>
                      {isExternal && (
                        <Icon className={cx('icon')} name={'arrow_right_line'} size={24} color={COLORS.gray900} />
                      )}
                    </div>
                  </div>
                );
              case 'FOLDER':
              case 'MY_PAGE':
                return (
                  <DrawerFolder
                    key={index}
                    title={menu.title}
                    subMenu={subMenu as ILNBType<IFolderLNBType & IMyPageLNBType>}
                    isEdit={isEdit}
                    isPreview={isPreview}
                    closeDrawer={() => setIsOpen(false)}
                    options={options}
                  />
                );
            }
          })}
      </ul>
      {hasLanguageChangeSwitch && (
        <div className={cx('language-menu', 'mobile')}>
          {Object.keys(LANGUAGE).map((language) => {
            return (
              <div
                key={language}
                className={cx('language', {
                  active: language === languageType,
                  DARK: checkIsDarkMode(backgroundColor),
                })}
                onClick={() => {
                  handleLanguageSwitch?.(language);
                }}
              >
                {language}
              </div>
            );
          })}
        </div>
      )}
      <button className={cx('menuBtn')} onClick={() => setIsOpen((prev) => !prev)}>
        <Icon name={iconName} size={32} color={style.color} />
      </button>
    </>
  );
};

export default Drawer;
