import { OFF, OnOffType } from '../../constants/common';
import { URL_TYPE, UrlType } from '../header/IMenu';
import { Nullable } from './common';

export const LINK_TYPE = {
  PAGE: 'PAGE',
  URL: 'URL',
} as const;

export type LinkType = keyof typeof LINK_TYPE;

export interface PageUrlType {
  urlType: UrlType;
  url: string;
}

export type LinkStyle = {
  linkStatusType: OnOffType;
  linkType: LinkType;
  url: string;
  pageSn?: Nullable<number>;
  pageUrl: PageUrlType;
};

export const DefaultLinkStyle: LinkStyle = {
  linkStatusType: OFF,
  linkType: LINK_TYPE.PAGE,
  pageSn: undefined,
  url: '',
  pageUrl: {
    urlType: URL_TYPE.INTERNAL,
    url: '',
  },
};
