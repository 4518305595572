import { Nullable } from '../common/common';
import { OnOffType } from '../../constants/common';
import { BorderRadiusStyle } from '../common/Border';

export const URL_TYPE = {
  INTERNAL: 'INTERNAL',
  EXTERNAL: 'EXTERNAL',
} as const;
export type UrlType = keyof typeof URL_TYPE;

export const HIDE_TYPE = {
  MENU_ONLY: 'MENU_ONLY',
  FULL: 'FULL',
  VISIBLE: 'VISIBLE',
} as const;

export type ILNBTypes =
  | ISingleLNBType
  | IFolderLNBType
  | IMyPageLNBType
  | ISingleLNBTypeWithStyle
  | IFolderLNBTypeWithStyle;
export type ILNBFormType = 'SINGLE' | 'FOLDER' | 'SUB_SINGLE' | 'EMPTY' | MyMenuType;
export type ILNBHideType = 'MENU_ONLY' | 'FULL' | 'VISIBLE';

export type ILNBType<T> = {
  formType: ILNBFormType;
  title: string;
  menuSn?: number;
  hideType: ILNBHideType;
} & T;

export type ISingleLNBType = {
  parentSn?: number;
  page: {
    pageSn?: Nullable<number>;
    urlType: UrlType;
    url: string;
  };
};

export type ISingleLNBTypeWithStyle = ISingleLNBType & {
  style: IMenuStyle;
};

export type IFolderLNBType = {
  subMenuList: ILNBType<ISingleLNBType>[];
};

export type IFolderLNBTypeWithStyle = IFolderLNBType & {
  style: IMenuStyle;
};

export type IMyPageLNBType = {
  subMenuList: ILNBType<IMyPageMenuType>[];
};

export type MyMenuType = 'MY_PAGE' | 'QNA' | 'RESUME_UPDATE';
export type IMyPageMenuType = {
  formType: MyMenuType;
  url?: string;
};

export interface IMenuStyle {
  backgroundBrandColorToggle: OnOffType;
  backgroundColor: string;
  borderRadius: BorderRadiusStyle;
  styleToggle: OnOffType;
  textBrandColorToggle: OnOffType;
  textColor: string;
}
