import { COLORS } from '../../constants/colors';

export const checkIsDarkMode = (backgroundColor: string) => {
  return backgroundColor === COLORS.black;
};

export const percentageToHexAlpha = (percentage: number) => {
  return Math.round((percentage / 100) * 255)
    .toString(16)
    .padStart(2, '0');
};
